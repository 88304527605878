<template>
  <footer class="border-gray-200 bg-white dark:border-gray-900 dark:bg-gray-900 z-10">
    <NuxtLink
      v-for="(item, i) in items"
      :key="i"
      :to="item.url"
      :target="item.target"
      class="fc-brand fs-12 c-pointer fw-600"
    >
      <div class="text-center">
        <BaseIcon
          v-if="!item?.nuxtIcon"
          class="fs-20 fw-500"
          :icon="item.icon"
        />
        <UIcon
          v-else
          class="fs-20 fw-500"
          :name="item.icon"
        />
      </div>
      <div class="text-center">
        {{ item.text }}
      </div>
    </NuxtLink>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      items: [
        // { url: 'https://csm.flightmate.com', text: 'CSM', icon: 'CSM' },
        { url: '/', text: 'Home', icon: 'i-mdi-home', target: '', nuxtIcon: true },
        { url: 'https://enterprise.flightmate.com/', text: 'Enterprise', icon: 'enterprice', target: '_blank' },
        { url: 'https://app.relaxad.com/campaigns', text: 'Relaxad', icon: 'relaxad', target: '_blank' },
        { url: '/analytics', text: 'Analytics', icon: 'analytics', icon: 'i-tabler-test-pipe', target: '', nuxtIcon: true },
      ],
    }
  },
}
</script>

<style lang="scss">
footer {
  border-top: 0.1rem $color-grey-cloud--dark solid;
  bottom: 0;
  height: 60px;
  position: fixed;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-evenly;
}
</style>
