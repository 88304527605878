<template>
  <BaseButton
    :color="color"
    navbar
  >
    <div
      v-if="nuxtIcon"
      class="w-4 mr-2.5"
    >
      <UIcon
        :name="icon"
      />
    </div>
    <BaseIcon
      v-else
      :icon="icon"
    />
    <transition name="fade-slowest">
      <span v-if="!$store.state.ui.isSidebarMini">{{ title }}</span>
    </transition>
  </BaseButton>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    nuxtIcon: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>
.fade-slowest {
  animation-delay: 0.3s;
}
</style>
