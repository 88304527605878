<template>
  <USlideover>
    <BaseModalCard>
      <div class="text-primary dark:text-gray-300 fs-24 fw-600">
        <div
          class="flex justify-between"
        >
          <span>
            Add new entry
          </span>
        </div>
      </div>
      <form>
        <div>
          <div class="fs-16 fw-600">
            Type of entry
          </div>
          <UTabs
            v-model="filterType"
            :items="cardTypes"
            :default-index="0"
            @change="onChange"
          />
        </div>
        <UFormGroup
          label="Title"
          size="xl"
        >
          <UInput
            v-model="selected.props.title"
            :disabled="selected._default"
          />
        </UFormGroup>
        <UFormGroup
          label="Data source"
          size="xl"
        >
          <USelectMenu
            v-model="selected"
            searchable
            option-attribute="fullName"
            :options="dataSourcesFilteredByType"
          />
        </UFormGroup>
      </form>
      <template #footer>
        <div
          class="flex justify-center gap-10"
        >
          <div class="w-1/2">
            <UButton
              :ui="{ rounded: 'rounded-full' }"
              variant="solid"
              color="gray"
              size="xl"
              rounded
              block
              @click="emits('close')"
            >
              Close
            </UButton>
          </div>
          <div class="w-1/2">
            <UButton
              :disabled="selected._default"
              :ui="{ rounded: 'rounded-full' }"
              variant="solid"
              color="primary"
              size="xl"
              block
              @click="addComponent"
            >
              Add
            </UButton>
          </div>
        </div>
      </template>
    </BaseModalCard>
  </uslideover>
</template>

<script setup>
import { infoCardTypes } from '~~/sharedUtils/infoCardTypes.js'
import { useUserPermissionsClient } from '~~/sharedUtils/useUserPermissionsClient'

const emits = defineEmits(['close'])

const { $store, $router } = useNuxtApp()
const { user } = useUserSession()
const { checkForPermissions } = useUserPermissionsClient()

const cardTypes = [{ label: 'Number' }, { label: 'Graph' }]

const filterType = ref(0)

const selectedCardType = computed(() => cardTypes[filterType.value].label)

const currentComponents = computed(() => $store.state.dynamicComponents.components)

const dataSourcesFilteredByType = computed(() => {
  return infoCardTypes
    .filter(infoCard => infoCard.type === selectedCardType.value && checkForPermissions(user.value?.permissions, infoCard.permission))
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
})

const selected = ref(dataSourcesFilteredByType.value[0] || { _default: true, props: { title: '' } })

async function onChange () {
  await nextTick()
  selected.value = dataSourcesFilteredByType.value[0] || { _default: true, props: { title: '' } }
}

function addComponent () {
  selected.value.key = Math.random()
  $store.dispatch('dynamicComponents/addComponent', selected.value)
  $router.push({ name: 'index' })
  // emits('close')
}
</script>

  <style lang="scss" scoped>
  .roundedCornersTop {
  border-top-left-radius: 2.8rem;
  border-top-right-radius: 2.8rem;
}

@include breakpoint_up(md) {
  .addNewDyanmicCard {
    height: calc(100% - 10.3rem);
    margin-right: 3.3rem;
    margin-top: 10.3rem;
  }
}

form > * {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

form > * > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

select {
  display: block;
  width: 100%;
  height: 6rem;
  padding: 0.6rem 0.6rem;
  margin: 2.4rem 0 2.4rem 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition:
    border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
}
select:focus {
  border: $input-border-focus;
}
</style>
