<template>
  <nav
    class="w-0 bg-white py-0 lg:w-64 md:w-60 dark:bg-gray-900 md:px-6"
    :class="{
      mini: isSidebarMini,
    }"
  >
    <div class="relative mb-5 mt-4 w-full overflow-hidden">
      <transition
        name="fade-fast1"
        mode="out-in"
      >
        <img
          v-if="isSidebarMini"
          key="1"
          src="~/assets/images/logo-round.png"
          height="44px"
          class="inline h-11 max-w-none overflow-hidden"
          rel="preload"
        >
        <img
          v-else
          key="2"
          src="~/assets/images/logo.png"
          class="inline h-11 max-w-none w-49 overflow-hidden"
          height="44px"
          width="190"
          rel="preload"
        >
      </transition>
    </div>
    <AppSidebarItemInternal
      :to="{ name: 'index' }"
      icon="home"
      title="Dashboard"
      class="my-1"
    />
    <AppSidebarItemInternal
      :to="{ name: 'analytics' }"
      icon="i-tabler-test-pipe"
      nuxt-icon
      title="Analytics"
      class="my-1"
    />
    <!-- <AppSidebarItemInternal
      :to="{ name: 'analytics-split-test' }"
      icon="teenyicons:ab-testing-outline"
      nuxt-icon
      title="Split test"
      class="my-1"
    /> -->
    <!-- <AppSidebarItemExternal
      to="https://docs.google.com/spreadsheets/d/1zqqFMbm5S3UBJ6_MJ1aY1TiRVTYlCw8k3YIcojEty4Q/edit#gid=1338992617"
      icon="uppfoljning"
      title="P&L sheet"
      class="my-1"
    />
    <AppSidebarItemExternal
      to="https://csm.flightmate.com"
      icon="CSM"
      title="CSM"
      class="my-1"
    /> -->
    <AppSidebarItemExternal
      to="https://enterprise.flightmate.com/"
      icon="enterprice"
      title="Enterprise"
      class="my-1"
    />
    <AppSidebarItemExternal
      to="https://flygstatistik.se/"
      icon="flygstatistik"
      title="Flygstatistik"
      class="my-1"
    />
    <AppSidebarItemExternal
      to="https://app.relaxad.com/campaigns"
      icon="relaxad"
      title="Relaxad"
      class="my-1"
    />
    <transition
      name="fade-slow"
    >
      <div
        v-if="isIndex"
        class="w-full"
      >
        <div
          class="my-6 w-full"
          :class="{
            'px-3.5': !isSidebarMini,
          }"
        >
          <div class="dashed-line" />
        </div>
        <!-- <AppSidebarItemInternal
      :to="{ name: 'admin-users' }"
      title="Users"
      icon="two-person"
      class="my-1"
    /> -->
        <AppSidebarItem
          title="Customise"
          icon="move"
          class="my-1"
          :color="isCustomiseEnabled ? 'primary' : null"
          @click="toggleCustomiseMode"
        />
        <!-- <AppSidebarItemInternal
        :to="{name: 'components'}"
        title="Components"
        icon="globe"
        class="my-1"
      /> -->
        <AppSidebarItem
          v-if="route.name === 'index'"
          title="Add new entry"
          icon="plus"
          :color="isAddNewEntryModal ? 'primary' : null"
          @click="showAddEntryModal"
        />
      </div>
    </transition>
  </nav>
</template>

<script setup>
import { SlideOverDynamicCardAdd } from '#components'

/**
 * @group App
 * Side bar, displays a series of links, both internal and external.
 * Opens "Add entry" modal
 * Toggles customisable mode
 */

const { $store, $breakpoints } = useNuxtApp()

const route = useRoute()

const isIndex = computed(() => ['index', 'analytics-saved'].includes(route.name))

const isAddNewEntryModal = computed(() => $store.state.modal.modalComponent === 'ModalDynamicCardAdd' && $store.state.modal.isModalOpen)
const isCustomiseEnabled = computed(() => $store.state.ui.isCustomisable && !isAddNewEntryModal.value && route.name)
const isSidebarMini = computed(() => $store.state.ui.isSidebarMini)

function toggleCustomiseMode () {
  $store.commit('ui/toggleCustomisable')
}

function showAddEntryModal () {
  const slideover = useSlideover()
  slideover.open(SlideOverDynamicCardAdd, { onClose: slideover.close })
}

// Close sidebar on mobile
watch(() => $breakpoints.lLg, () => {
  if (!$breakpoints.lLg && !$store.state.ui.isSidebarMini) {
    $store.commit('ui/isSidebarMini', true)
  } else if ($breakpoints.lLg && $store.state.ui.isSidebarMini) {
    $store.commit('ui/isSidebarMini', false)
  }
})
</script>

<style lang="scss" scoped>
nav {
  width: 239px;
  position: fixed;
  top: 0;
  z-index: 30;
  height: 100%;
  margin: 0;
  padding: 0 24px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-900);
  box-shadow: 4px 0 4px rgba(43, 45, 55, 0.01);
  transition: width 0.8s ease-in;

  @include breakpoint_down(md) {
    width: 0px;
  }

  @include breakpoint_up(xl) {
    width: 255px;
    padding: 0 32px;
  }
}

nav.mini {
  width: 96px;

  @include breakpoint_up(xl) {
    width: 112px;
  }
}

.dashed-line {
  width: 100%;
  border-top: 2px dashed $border-color-primary;
}
</style>
