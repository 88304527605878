<template>
  <div
    class="navbar f-600 bg-white px-5 py-3.5 text-gray-500 dark:bg-gray-900 dark:text-gray-300 z-20"
    :class="{
      'sidebar--collapsed': isSidebarMini,
      'navbar--mini': isCollapsed,
    }"
    :style="style__navbarHeight"
  >
    <FlexRow
      no-wrap
      align-v="center"
      class="navbar--leftSide"
    >
      <FlexCol
        class="d-sMd"
      >
        <span
          class="fc-primary fs-38 title mb-3 dark:text-gray-300"
        >{{ displayTitle }}</span>
        <span
          class="fs-16 fade fw-600"
          :class="{
            'd-none': (height === minHeight),
          }"
          :style="style__displayDate"
        >
          {{ displayDate }}
        </span>
      </FlexCol>
      <FlexRow
        align-v="center"
        no-wrap
        class="d-lMd c-pointer"
        @click="$store.commit('ui/toggleSidebarMini')"
      >
        <BaseIcon
          class="fs-20"
          :icon="isSidebarMini ? 'arrow-show' : 'arrow-hide'"
        />
        <span>
          {{ (isSidebarMini) ? 'Show menu' : 'Hide menu' }}
        </span>
      </FlexRow>
    </FlexRow>
    <FlexRow
      no-wrap
      align-v="center"
      class="navbar--rightSide"
    >
      <!-- Date -->
      <div
        class="d-lMd mr-8"
      >
        {{ displayDate }}
      </div>
      <!-- Notifications -->
      <!-- <BaseIcon
        class="fs-20 mx-4 p-6 c-pointer notification"
        icon="bell"
      /> -->

      <BaseIcon
        class="d-sMd fs-20 c-pointer mx-1 p-1.5"
        icon="plus"
        @click="showAddNewEntryModal"
      />
      <!-- Customise -->
      <BaseIcon
        class="d-sMd fs-20 c-pointer mx-1 p-1.5"
        :class="{
          'fc-brand bg-white dark:bg-gray-700 dark-text-gray-100 shadow p-1.5': isCustomiseEnabled,
        }"
        icon="move"
        @click="toggleCustomiseMode"
      />
      <!-- Settings -->
      <BaseIcon
        class="d-sMd fs-20 c-pointer mx-1 p-1.5"
        :class="{
          'fc-brand bg-white dark:bg-gray-700 dark-text-gray-100 shadow p-1.5': isSettingsRoute,
        }"
        icon="setting"
        @click="isSettingsRoute ? $router.go(-1) : $router.push({ name: 'me' })"
      />
      <!-- <div
        class="d-lMd mr-18"
      >
        {{ market }}
      </div> -->
      <AppNavbarUser />
      <!-- <div
        class="d-lMd fs-20"
      >
        <AppLanguageSwitcher />
      </div> -->
    </FlexRow>
  </div>
</template>

<script setup>
import { SlideOverDynamicCardAdd } from '#components'

const today = new Date()
const { $format, $router, $store, $route } = useNuxtApp()

const pageTitle = ref('Dashboard')
const displayMonth = ref($format(today, 'LLLL'))
const displayDate = ref($format(today, 'LLLL d, yyyy'))
const market = ref('Flygresor.se')
const maxHeight = ref(104)
const minHeight = ref(78)
const height = ref(104)
const opacity = ref(1)
const threshold = ref(0)
const initScrollPosY = ref(0)
const isScrolling = ref(false)
const isCollapsed = ref(false)

const isIndex = computed(() => $router.currentRoute.value.name === 'index')
const isSettingsRoute = computed(() => $router.currentRoute.value.name === 'me')
const displayTitle = computed(() => isCollapsed.value ? displayMonth.value : pageTitle.value)
const style__navbarHeight = computed(() => `height: ${height.value}px`)
const style__displayDate = computed(() => `opacity: ${opacity.value}`)
const isCustomiseEnabled = computed(() => $store.state.ui.isCustomisable)
const isSidebarMini = computed(() => $store.state.ui.isSidebarMini)

function showAddNewEntryModal () {
  const slideover = useSlideover()
  slideover.open(SlideOverDynamicCardAdd, { onClose: slideover.close })
}

function handleTouchStart (event) {
  if (window.innerWidth >= 768 || event.touches.length > 1) {
    return
  }
  initScrollPosY.value = window.pageYOffset
  isScrolling.value = true
}

function handleTouchMove () {
  if (window.innerWidth >= 768) {
    return
  }
  const distance = initScrollPosY.value - window.pageYOffset
  if (isCollapsed.value && distance <= 0) {
    return
  }
  threshold.value = (distance < 0) ? 0 : 70
  updateMobileNavHeight(distance)
  if (opacity.value && distance !== 0) {
    opacity.value = 0
  }
}

function handleTouchEnd () {
  if (window.innerWidth >= 768) {
    return
  }
  isCollapsed.value = window.pageYOffset > threshold.value
  height.value = (isCollapsed.value) ? minHeight.value : maxHeight.value
  opacity.value = (isCollapsed.value) ? 0 : 1
  if (!isCollapsed.value) {
    window.scrollTo(0, 0)
  }
  isScrolling.value = false
}

function toggleCustomiseMode () {
  $store.commit('ui/toggleCustomisable')
}

function updateMobileNavHeight (distanceScroll) {
  if (isScrolling.value) {
    if (distanceScroll < 0) {
      height.value = Math.max(minHeight.value, height.value + distanceScroll)
    } else if (window.pageYOffset <= 40) {
      height.value = Math.min(maxHeight.value, height.value + distanceScroll)
    }
  } else {
    height.value = (isCollapsed.value) ? minHeight.value : maxHeight.value
  }
}

onMounted(() => {
  window.addEventListener('touchstart', handleTouchStart)
  window.addEventListener('touchmove', handleTouchMove)
  window.addEventListener('touchend', handleTouchEnd)
})

onUnmounted(() => {
  window.removeEventListener('touchstart', handleTouchStart)
  window.removeEventListener('touchmove', handleTouchMove)
  window.removeEventListener('touchend', handleTouchEnd)
})
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  // background-color: inherit;
  transition: height 0.3s linear;
  justify-content: space-between;
  // color: $font-color-tertiary;

  &--leftSide {
    margin-left: 0;
    transition: margin-left 0.8s ease-in;
  }

  &--rightSide {
    @include breakpoint_down(md) {
      font-size: 24px;
    }
  }

  .title {
    top: -4px;
  }

  &--mini {
    border-bottom: 1px solid #eaeaea;
  }

  @include breakpoint_up(md) {
    // background-color: $color-white;
    // color: $font-color-secondary;
    height: 70px !important;
    box-shadow: 0 4px 4px rgba(43, 45, 55, 0.01);
    transition: height 0.8s ease-in;

    &--leftSide {
      margin-left: 239px;
    }

    &.sidebar--collapsed {
      .navbar--leftSide {
        margin-left: 96px;
      }
    }

    .notification {
      margin-right: 22px;
    }
  }

  @include breakpoint_up(xl) {
    .navbar--leftSide {
      margin-left: 255px;
    }

    &.sidebar--collapsed {
      .navbar--leftSide {
        margin-left: 112px;
      }
    }
  }
}

.fade {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.enabledBG {
  background-color: white;
  padding: 6px;
  box-shadow: 0 0 6px 6px rgba(49, 82, 122, 0.1);
}
</style>
