<template>
  <a
    :href="to"
    target="_blank"
  >
    <AppSidebarItem
      :title="title"
      :color="color"
      :icon="icon"
    />
  </a>
</template>

<script>
export default {
  props: {
    to: { type: String, default: '' },
    title: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
  },
}
</script>

<style>
</style>
