<template>
  <nuxt-link
    :to="to"
    active-class="primary rounded-xl"
  >
    <AppSidebarItem
      :title="title"
      :icon="icon"
      :nuxt-icon="nuxtIcon"
    />
  </nuxt-link>
</template>

<script>
export default {
  props: {
    to: { type: Object, default: () => ({}) },
    title: { type: String, default: '' },
    color: { type: String, default: '' },
    icon: { type: String, default: '' },
    nuxtIcon: { type: Boolean, default: false },
  },
  computed: {
    isCurrentPage () {
      if (this.$route.name) {
        return this.$route.name.split('___')[0] === this.to.name
      }
      return ''
    },
  },
}
</script>

<style lang="scss">

</style>
